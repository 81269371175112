import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import Summary from './components/Summary';
import Questions from './components/Questions';
import Instruction from './components/Instruction';
import Login from './components/Login';


function App() {
  return (
    <Switch>
      <Route path="/home" exact component={Home} />
      <Route path="/" exact component={Login} />
      <Route path="/exam/instructions" exact component={Instruction} />
      <Route path="/exam/questions" exact component={Questions} />
      <Route path="/exam/summary" exact component={Summary} />
    </Switch>
  );
}

export default App;
