import React from 'react';

const Timer = ({ questionList, onClickJumpButton }) => {
  return (
    <div className="col-lg-4">
      <div className="jump-section display-pc-only">
        <div className="row">
          <div className="col-lg-12 col-md-6">
            <div className="categories mt-4">
              <h4>Jump to</h4>
              <div className="jump-ques">
                <ul className="jump-ques-ul">
                  {questionList && questionList.length > 0 ? questionList.map((question, index) => (<li className="jump-btn"><button type="button" onClick={onClickJumpButton} value={index} className={question.is_answered ? 'done' : ''}>{index + 1}</button></li>)) : null}
                </ul>
              </div>
              <div className="d-flex jump-bottom-sec">
                <p className="flex-grow-1"><i className="fa fa-check-circle color-theme" /> Answered</p>
                <p><i className="fa fa-check-circle text-secondary" /> Not Answered</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timer