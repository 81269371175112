import React, { useState, useEffect } from 'react';
import Jump from './Jump';
import JumpSidebar from './JumpSidebar';
import { useHistory } from 'react-router-dom';

import service from '../../service/Api';

const Question = ({ questions, paperSelectedOptions }) => {
  const history = useHistory();
  const user_etest_series_item_id = localStorage.getItem('user_etest_series_item_id');
  const [active, setActive] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [previousQuestion, setPreviousQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(true);

  useEffect(() => {
    initializeQuestionList(questions, paperSelectedOptions);
  }, [questions, paperSelectedOptions])

  useEffect(() => {
    console.log('INSIDE')
    displayQuestions(questions, currentQuestionIndex);
    handleDisableButton(previousQuestion, currentQuestionIndex, nextQuestion, numberOfQuestions);
  }, [questions, currentQuestionIndex, previousQuestion, nextQuestion, numberOfQuestions])

  const initializeQuestionList = (questions, paperSelectedOptions) => {
    console.log('paperSelectedOptions', paperSelectedOptions)
    const results = questions.map((question) => {
      const currentOption = paperSelectedOptions.find(option => option.question_id === question.id);
      if (currentOption) {
        return {
          id: question.id,
          is_answered: true,
          selected_option: currentOption.option_id
        }
      } else {
        return {
          id: question.id,
          is_answered: false,
          selected_option: ''
        }
      }
    })
    console.log(results)
    setQuestionList(results);
  }
  const displayQuestions = (questions, currentQuestionIndex) => {
    if (questions && questions.length > 0) {
      setCurrentQuestion(questions[currentQuestionIndex]);
      setNextQuestion(questions[currentQuestionIndex + 1]);
      setPreviousQuestion(questions[currentQuestionIndex - 1]);
      setNumberOfQuestions(questions.length)
    }
  };

  const handleDisableButton = (previousQuestion, currentQuestionIndex, nextQuestion, numberOfQuestions) => {
    if (!previousQuestion || currentQuestionIndex === 0) {
      setPreviousButtonDisabled(true);
    } else {
      setPreviousButtonDisabled(false);
    }

    if (!nextQuestion || currentQuestionIndex + 1 === numberOfQuestions) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }

  const getCheckedRadioButton = (option, paperSelectedOptions) => {
    if (questionList.length > 0) {
      return option.id === questionList[currentQuestionIndex]['selected_option']
    }
    return false
  }

  const getQuestionOptions = (options) => {
    return options.map((option, index) => (
      <div className="form-check exam-option" key={option.id}>
        <input className="form-check-input" type="radio" name="inlineRadioOptions" checked={getCheckedRadioButton(option, paperSelectedOptions)} value={option.id} id={option.id} />
        <label className="form-check-label" htmlFor={option.id}><span>{index + 1} - </span> {<div dangerouslySetInnerHTML={{ __html: option.title }} />}</label>
      </div>
    ))
  }

  const handleNextButtonClick = () => {
    if (nextQuestion) {
      console.log('nextQuestion')
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      displayQuestions(questions, currentQuestionIndex);
    }
  };

  const handlePreviousButtonClick = () => {
    if (previousQuestion) {
      console.log('previousQuestion')
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      displayQuestions(questions, currentQuestionIndex);
    }
  };

  const handleSubmitButton = () => {
    if (window.confirm('Are you sure you want to submit?')) {
      endTest();
    }
  };

  const endTest = async () => {
    await service.getList('api/mock_test_paper/submit_test', {
      user_etest_series_item_id: user_etest_series_item_id
    })
    history.push(`/exam/summary`);
  }

  const handleButtonClick = (e) => {
    switch (e.target.id) {
      case 'next-button':
        handleNextButtonClick();
        break;

      case 'previous-button':
        handlePreviousButtonClick();
        break;

      case 'submit-button':
        handleSubmitButton();
        break;

      default:
        break;
    }

  };

  const onChangeValue = (event) => {
    console.log('onChangeValue', questionList[currentQuestionIndex])
    const updatedData = {
      id: currentQuestion.id,
      is_answered: true,
      selected_option: event.target.value
    }
    setQuestionList(questionList.map((item) => item.id === currentQuestion.id ? updatedData : item));
    service.create('api/mock_test_paper/selected_option', {
      "user_etest_series_item_id": user_etest_series_item_id,
      "question_id": currentQuestion.id,
      "option_id": event.target.value
    })
  }

  const jumpButtonHandler = (event) => {
    console.log('jumpButtonHandler', event.target.value)
    setCurrentQuestionIndex(parseInt(event.target.value));
    displayQuestions(questions, currentQuestionIndex);
    setActive(!active);
  }

  const jumpSidebarHandler = () => {
    setActive(!active)
  }

  let content = <h1>Loading...</h1>
  if (currentQuestion && questions && questions.length > 0) {
    content = <React.Fragment>
      <div className="row">
        <div className="col-lg-8">
          <div className="exam-ques-sec mt-4">
            <div className="exam-ques-div">
              <h3 className="exam-ques"><span>Q.{currentQuestionIndex + 1}</span> {<div dangerouslySetInnerHTML={{ __html: currentQuestion.title }} />}</h3>
              <div onChange={onChangeValue}>
                {getQuestionOptions(currentQuestion.question_avail_options)}
              </div>
              <div className="courses-pagination">
                <ul className="exam-button pagination d-flex">
                  <li className="jump-btn">
                    <button
                      disabled={previousButtonDisabled}
                      type="button"
                      id="previous-button"
                      className="btn exam-btn"
                      onClick={handleButtonClick}
                    >
                      &lt;&lt; Prev
                    </button>
                  </li>
                  <li className="jump-btn flex-grow-1">
                    <button
                      disabled={nextButtonDisabled}
                      id="next-button"
                      type="button"
                      className="btn exam-btn"
                      onClick={handleButtonClick}
                    >Next &gt;&gt;</button>
                  </li>
                  <li className="jump-btn">
                    <button
                      id="submit-button"
                      type="button"
                      className="btn exam-btn"
                      onClick={handleButtonClick}
                    >Submit</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Jump questionList={questionList} onClickJumpButton={jumpButtonHandler} />
      </div>
      <JumpSidebar active={active} questionList={questionList} onClickJumpButton={jumpButtonHandler} onClickJumpSidebar={jumpSidebarHandler} />
    </React.Fragment>
  }
  return (
    content
  )
}

export default Question;