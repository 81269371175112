import React, { useState, useEffect } from 'react';
import Question from './Question';
import service from '../../service/Api';
import TestPaperTitle from './TestPaperTitle';

import './Question.css';


const Questions = () => {
  const user_etest_series_item_id = localStorage.getItem('user_etest_series_item_id');

  const [questions, setQuestions] = useState([]);
  const [paperTitle, setPaperTitle] = useState('');
  const [paperDetails, setPaperDetails] = useState(null);
  const [paperSelectedOptions, setPaperSelectedOptions] = useState([]);

  useEffect(() => {
    console.log('INSIDE MAIN HOOK')
    fetchTestPaperDetails(user_etest_series_item_id);
  }, [user_etest_series_item_id])

  const fetchTestPaperDetails = async (user_etest_series_item_id) => {
    const response = await service.getList(
      'api/mock_test_paper/details', {
      user_etest_series_item_id: user_etest_series_item_id,
    });
    const { mock_test_paper, paper_selected_opts, ...mockTestDetails } = response.data;
    setPaperDetails(mockTestDetails);
    setPaperTitle(mock_test_paper.title);
    setQuestions(mock_test_paper.mock_questions);
    setPaperSelectedOptions(paper_selected_opts);
  }



  return (
    <section className="pt-5 pb-120 exam-section">
      <div className="container">
        <TestPaperTitle
          paperDetails={paperDetails}
          paperTitle={paperTitle}
        />
        <Question
          questions={questions}
          paperSelectedOptions={paperSelectedOptions}
        />
      </div>
    </section>
  )
};

export default Questions;