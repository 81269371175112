import api from './AuthInterceptor';

const Service = {
  getList: async (api_url, params = {}) => {
    console.log('params', params);
    return api({
      url: api_url,
      method: 'get',
      params
    })
  },
  getDetails: async (api_url, id) => {
    return api({
      url: `${api_url}${id}`,
      method: 'get',
    })
  },
  create: async (api_url, data) => {
    return api({
      url: api_url,
      method: 'post',
      data
    })
  },
  update: async (api_url, id, data) => {
    return api({
      url: `${api_url}${id}`,
      method: 'put',
      data
    })
  },
  updateItem: async (api_url, data, params) => {
    return api({
      url: api_url,
      method: 'patch',
      params
    })
  },
  remove: async (api_url, id) => {
    return api({
      url: `${api_url}${id}`,
      method: 'delete',
    })
  },
}

export default Service;