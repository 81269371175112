import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import service from '../service/Api';

import './Summary.css'


const Summary = () => {

  const user_etest_series_item_id = localStorage.getItem('user_etest_series_item_id');

  const history = useHistory();
  const [summary, setSummary] = useState(null)
  useEffect(() => {
    fetchTestSummary(user_etest_series_item_id);
  }, [user_etest_series_item_id])

  const fetchTestSummary = async (user_etest_series_item_id) => {
    const response = await service.getDetails('/api/mock_test_paper/fetch_test_summary/', user_etest_series_item_id);
    setSummary(response.data);
  }

  const buttonClickHandler = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_etest_series_item_id');
    history.push('/')
  }

  let content = <h1>Loading...</h1>
  if (summary) {
    content = <div className="container mt-5">
      <h2 className="my-3 text-center">Test Finished</h2>
      <div className="row submit-head">
        <div className="col-md-12">
          <h4 className="my-3">Final Sumbimssion</h4>
          <p>One of the most important things you should know and never neglect when you own a website or run an
          online shop is the legal aspect of the trade. In this article, we are going to provide all the
          information necessary to completely understand privacy policies, how they work and why you need
    them. </p>
          <p>It is essential to understand that even if you don't explicitly collect any information from your
          visitors, most websites usually use cookies that store visitors' data every time they drop by web
          pages. It can be a slippery slope when left unmanaged, so the existence of a privacy policy is of
    utmost importance.</p>
        </div>
        <div className="col-md-6">
          <div className="submit-sec mt-30">
            <div className="submit-div">
              <div className="icon">
                <i className="fa fa-check-circle" />
              </div>
              <div className="cont">
                <h6>Total Questions</h6>
                <span>{summary.total_question}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="submit-sec mt-30">
            <div className="submit-div">
              <div className="icon">
                <i className="fa fa-clock-o" />
              </div>
              <div className="cont">
                <h6>Total Correct</h6>
                <span>{summary.nos_correct_answers}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="submit-sec mt-30">
            <div className="submit-div">
              <div className="icon">
                <i className="fa fa-check-circle" />
              </div>
              <div className="cont">
                <h6>Total Attempts</h6>
                <span>{summary.nos_question_attempt}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="submit-sec mt-30">
            <div className="submit-div">
              <div className="icon">
                <i className="fa fa-exclamation-circle text-danger" />
              </div>
              <div className="cont">
                <h6>Not Correct</h6>
                <span className="text-danger">{summary.nos_question_attempt - summary.nos_correct_answers}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <button type="button" className="btn btn-warning mb-3" onClick={buttonClickHandler}>Exit Exam</button>
        </div>
      </div>
    </div>
  }
  return (
    <section>
      {content}
    </section>
  )
};

export default Summary;