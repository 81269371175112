import React from 'react';

const Timer = ({ time }) => {
  const formatTime = (time) => {
    if(!time){
      return `00`
    }
    if(time && `${time}`.length === 1){
      return `0${time}`
    }
    return time;
  }
  
  return (
    <div className="time-icon">
      <div className="pt-2">
        <span key={1} className="mr-2 font-20"><i className="fa fa-clock-o" /></span>
        <label key={2} className="exam-timer">{formatTime(time.hours)}:{formatTime(time.minutes)}:{formatTime(time.seconds)}</label>
      </div>
    </div>
  )
}

export default Timer