import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import service from '../service/Api';
import io from 'socket.io-client';
import './Home.css'

const socketUrl = process.env.REACT_APP_API_ENDPOINT_URL


const Login = () => {
  const history = useHistory();
  const [image, setImage] = useState('');

  useEffect(() => {
    let interval;
    const socket = io(socketUrl, { transports: ["websocket"] })

    socket.on('connect', () => {
      console.log('connected')
    })

    socket.on('connect_error', (error) => {
      console.log('connected', error)
    })

    socket.on('FETCH_SOCKET_ID', (socketId) => {
      console.log('sessionID', socketId)
      fetchQrCodeImage(socketId)
      interval = setInterval(() => {
        console.log('Fetchinng QR Code');
        fetchQrCodeImage(socketId)
      }, 1000 * 60)
    })

    socket.on('LOGIN_SUCESS', (data) => {
      console.log('LOGIN_SUCESS', data);
      localStorage.setItem('user_etest_series_item_id', data.user_etest_series_item_id);
      localStorage.setItem('auth_token', data.auth_token);
      history.push('/home');
    });

    return () => {
      console.log('component will mount')
      socket.disconnect();
      clearInterval(interval);
    }
  }, [history]);


  const fetchQrCodeImage = async (socketId) => {
    const response = await service.getList('/api/mock_test_paper/generateQrCode', {
      socket_id: socketId,
    });
    setImage(response.data.url);
  }

  return (
    <div className="container exam-start-sec">
      <div className="row h-100">
        <div className="col-sm-12 align-self-center">
          <div className="card card-block mx-auto text-center">
            <img src={image} alt="Qr Code" width={170} />
            <p>Please Scan Qr Code to login</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Login;