import React from 'react';
import service from '../service/Api';
import { useHistory, useLocation } from 'react-router-dom';
import './Instruction.css'

const Instruction = () => {
  const history = useHistory();
  const handleStartTest = async () => {
    await service.getDetails('api/mock_test_paper/update_test_start_time/', localStorage.getItem('user_etest_series_item_id'))
    history.push(`/exam/questions`);
  }

  return (
    <section>
      <div className="container mt-5">
        <h2 className="my-2 text-center">Exam Instruction</h2>
        <div className="row instruction-head">
          <div className="col-md-12">
            <h4 className="my-3">About Test</h4>
            <p>One of the most important things you should know and never neglect when you own a website or run an online
            shop is the legal aspect of the trade. In this article, we are going to provide all the information necessary
        to completely understand privacy policies, how they work and why you need them. </p>
            <p>It is essential to understand that even if you don't explicitly collect any information from your visitors,
            most websites usually use cookies that store visitors' data every time they drop by web pages. It can be a
        slippery slope when left unmanaged, so the existence of a privacy policy is of utmost importance.</p>
            <div className="instr-sec">
              <h4 className="my-3">Guideline</h4>
              <p>One of the most important things you should know and never neglect when you own a website or run an online
          shop is the legal aspect of the trade. </p>
              <ol>
                <li>You have to 20 Minute to complete this test</li>
                <li>The Paper contains a total of 15 questions for 15 Marks</li>
                <li>You have to 20 Minute to complete this test</li>
                <li>The Paper contains a total of 15 questions for 15 Marks</li>
                <li>You have to 20 Minute to complete this test</li>
                <li>The Paper contains a total of 15 questions for 15 Marks</li>
                <li>You have to 20 Minute to complete this test</li>
                <li>The Paper contains a total of 15 questions for 15 Marks</li>
                <li>You have to 20 Minute to complete this test</li>
                <li>The Paper contains a total of 15 questions for 15 Marks</li>
              </ol>
              <p>It is essential to understand that even if you don't explicitly collect any information from your visitors,
              most websites usually use cookies that store visitors' data every time they drop by web pages. It can be a
          slippery slope when left unmanaged, so the existence of a privacy policy is of utmost importance.</p>
            </div>
            <form>
              <div className="form-group">
                <button type="button" className="btn btn-success w-25" onClick={handleStartTest}><i className="fa fa-clock-o" /> Agree and Start Test</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Instruction;