import moment from 'moment';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import service from '../../service/Api';
import isEmpty from '../../utils/is-empty';

import Timer from './Timer'

const TestPaperTitle = ({ paperDetails, paperTitle }) => {
  const history = useHistory();
  const [time, setTime] = useState([]);

  const user_etest_series_item_id = localStorage.getItem('user_etest_series_item_id');

  useEffect(() => {
    let interval = null;
    startTimer(interval, paperDetails, history, user_etest_series_item_id);
    return () => {
      console.log('components will mount')
      clearInterval(interval);
    }
  }, [paperDetails, history, user_etest_series_item_id])

  const startTimer = (interval, paperDetails, history, user_etest_series_item_id) => {
    if (!isEmpty(paperDetails)) {
      const countDownTime = moment(paperDetails.test_start_datetime).add(paperDetails.duration_minutes, 'minutes').format();
      console.log('countDownTime', countDownTime, moment().format())
      interval = setInterval(async () => {
        const now = moment().format();
        const distance = moment(countDownTime).diff(moment(now));
        console.log('distance', distance)
        const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          clearInterval(interval);
          setTime({
            hours: 0,
            minutes: 0,
            seconds: 0
          })

          await service.getList('api/mock_test_paper/submit_test', {
            user_etest_series_item_id: user_etest_series_item_id
          })

          history.push(`/exam/summary`);
        } else {
          setTime({
            hours,
            minutes,
            seconds,
            distance
          })
        }
      }, 1000);
    }
  }

  return (
    <React.Fragment>
      <h3 className="text-center display-mob mb-3">{paperTitle}</h3>
      <div className="exam-head exam-head px-4 py-3">
        <div className="exam-head-div">
          <ul className="d-flex">
            <li className="flex-grow-1 mt-3 pt-1 display-pc-only">
              <h6>{paperTitle}</h6>
            </li>
            <li >
              <Timer time={time} />
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TestPaperTitle