import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Home.css'

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (query.get("auth_token") && query.get('user_etest_series_item_id')) {
      localStorage.setItem('auth_token', query.get("auth_token"));
      localStorage.setItem('user_etest_series_item_id', query.get('user_etest_series_item_id'));
    }
  }, [query]);

  const buttonClickHandler = () => {
    history.push(`/exam/instructions`)
  }

  return (
    <div className="container exam-start-sec">
      <div className="row h-100">
        <div className="col-sm-12 align-self-center">
          <div className="card card-block mx-auto text-center">
            <img src="logo.png" alt="Logo" width={170} />
            <p>Ready to begin, Start Now</p>
            <button onClick={buttonClickHandler} type="button" className="btn btn-warning">Start</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Home;