import React from 'react';

const JumpSidebar = ({ active, questionList, onClickJumpButton, onClickJumpSidebar }) => {

  return (
    <React.Fragment>
      <div id="jump-sidebar-btn">
        <div className="container-fluid" style={{ padding: 0 }}>
          <button type="button" id="JumpSidebarCollapse" className={active ? 'active' : ''} onClick={onClickJumpSidebar}>
            <span />
            <span />
            <span />
          </button>
        </div>
      </div>
      <nav id="jump-sidebar-section" className={`jump-section ${active ? 'active' : ''}`}>
        <div className="row">
          <div className="col-lg-12 col-md-6">
            <div className="categories">
              <h4>Jump to</h4>
              <div className="jump-ques">
                <ul className="jump-ques-ul">
                  {questionList && questionList.length > 0 ? questionList.map((question, index) => (<li className="jump-btn"><button type="button" value={index} onClick={onClickJumpButton} className={question.is_answered ? 'done' : ''}>{index + 1}</button></li>)) : null}
                </ul>
              </div>
              <div className="d-flex jump-bottom-sec">
                <p className="flex-grow-1"><i className="fa fa-check-circle color-theme" /> Answered</p>
                <p><i className="fa fa-check-circle text-secondary" /> Not Answered</p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default JumpSidebar;