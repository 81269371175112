import axios from 'axios';
import history from '../history'
import { notification } from 'antd';

const service = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  timeout: 60000
})


// API Request interceptor
service.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('auth_token');

  if (jwtToken) {
    config.headers['x-auth-token'] = jwtToken
  }

  return config
}, error => {
  // Do something with request error here
  console.log('HEEEEEEEEE', error)
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

  console.log('response', response.data)
  const { status, message } = response.data;
  console.log('notification.error', response.data)
  if (status !== 'success') {
    notification.error(message);
  }
  return response.data
}, (error) => {

  if (!error.response) {
    notification.error({message: 'NETWORK ERROR'})
  } else {
    const code = error.response.status
    const response = error.response.data
    console.log('code', code)
    
    if (code === 400) {
      notification.error({message: response.message})
    }

    	// Remove token and redirect 
	if (error.response.status === 401 || error.response.status === 403) {
    notification.error({message: 'Authentication Fail', description: 'Please login again'})
		localStorage.removeItem('auth_token');
		localStorage.removeItem('user_etest_series_item_id');
	}
    
    return Promise.reject(error)
  }
});

export default service